import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Statistics from "views/Statistics/Statistics";

const axios = require("axios");

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const devicearray = window.deviceids;
  const [imagetaken, setImageTaken] = React.useState(0);

  if (imagetaken == 0) {
    var statisticstemp = {};
    for (let i = 0; i < devicearray.length; i++) {
      const boxid = devicearray[i];
      axios.post(window.serverip + "/statisticsbyboxid/" + boxid, {
        sessionid: sessionStorage.getItem("sessionid")
      })
        .then((response) => {
          statisticstemp[boxid] = response.data;
          var amount = 0;
          if (Object.keys(statisticstemp).length == devicearray.length) {
            //console.log("both fetched");
            //console.log(statisticstemp);
            for (const key in statisticstemp) {
              const element = statisticstemp[key];
              amount = amount + element.length;
            }
            if (imagetaken != amount) {
              setImageTaken(amount);
            }
          }
        }, (error) => {
          console.log(error);
        });
    }
  }

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Connected Selfieboxes:</p>
              <h3 className={classes.cardTitle}>
                {devicearray.length}
              </h3>
            </CardHeader>
            <CardFooter stats>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Total pictures taken:</p>
              <h3 className={classes.cardTitle}>{imagetaken}</h3>
            </CardHeader>
            <CardFooter stats>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Statistics nobar></Statistics>
        </GridItem>
      </GridContainer>
    </div>
  );
}
