import React from 'react';
var axios = require("axios");

function Centerlogo(props) {
    const [data, setData] = React.useState(undefined);


    const centerid = props.centerid;

    if (data === undefined) {
        axios.post(window.serverip + "/getCenterbyId/" + centerid, {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                setData(response.data[0]);
            }, (error) => {
                console.log(error);
            });
        return null;
    }

    return (
        <img style={{position: "absolute", bottom: "3%", width: "80%", marginLeft: "10%", marginRight: "10%"}} src={data.centerlogo}>

        </img>
    )
}

export default Centerlogo
