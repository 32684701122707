import React from 'react'
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

function SceneMedia(props) {
    const [type, setType] = React.useState(props.type);
    const [backgroundImageLoaded, setBackGroundImageLoaded] = React.useState(undefined);
    if (props.setType !== undefined) {
        if (props.type !== type) {
            props.setType(type);
        }
    }


    let sceneid = props.sceneid;

    let backgroundimg = window.serverurl + "/" + sceneid + "_back.png";
    if (type == undefined) {
        if (!UrlExists(backgroundimg)) {
            backgroundimg = window.serverurl + "/" + sceneid + "_back.mp4";
            if (!UrlExists(backgroundimg)) {
                setType("error");
            } else {
                setType("video");
            }
        } else {
            setType("photo");
        }

        return <CircularProgress></CircularProgress>;
    } else {
        if (type == "video") {
            backgroundimg = window.serverurl + "/" + sceneid + "_back.mp4";
        }
    }

    if (backgroundImageLoaded === undefined) {
        fetch(backgroundimg)
            .then(resp => resp.blob())
            .then(blob => {
                //console.log(blob.type);
                let url = URL.createObjectURL(blob);

                setBackGroundImageLoaded(url);
            }).catch(error => {
                setBackGroundImageLoaded("");
            });
        return <CircularProgress></CircularProgress>;
    }

    function UrlExists(url) {
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);

        try {
            http.send();
        } catch (error) {
            return false;
        }

        return http.status != 404 && (http.responseURL != window.serverurl + "/");
    }


    if (type === "error" || backgroundImageLoaded == "") {
        return null;
    } else if (type === "video") {
        return (<video style={{ width: "100%" }} src={backgroundImageLoaded} onError={() => { setType("error") }} autoPlay={true} muted loop></video>)
    }

    return (<img style={{ width: "100%" }} src={backgroundImageLoaded} onError={() => { setType("video") }}></img>);
}

export default SceneMedia
