import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Select, MenuItem, Typography } from "@material-ui/core";
import DataPreparer from "./DataDisplayer";

var axios = require("axios");

const useStyles = makeStyles(styles);


export default function Statistics(props) {
    const [statistics, setStatistics] = React.useState(undefined);
    const [selectedYear, setSelectedYear] = React.useState("none");
    const [selectedMonth, setSelectedMonth] = React.useState("none");
    const [selectedDay, setSelectedDay] = React.useState("none");
    var devicearray = window.deviceids;
    
    if (devicearray.length == 0) {
        return <div>No devices connected.</div>
    }


    if (statistics == undefined) {
        var statisticstemp = {};
        for (let i = 0; i < devicearray.length; i++) {
            const boxid = devicearray[i];
            axios.post(window.serverip + "/statisticsbyboxid/" + boxid, {
                sessionid: sessionStorage.getItem("sessionid")
            })
                .then((response) => {
                    statisticstemp[boxid] = response.data;
                    var amount = 0;
                    if (Object.keys(statisticstemp).length == devicearray.length) {
                        //console.log("both fetched");
                        //console.log(statisticstemp);
                        for (const key in statisticstemp) {
                            const element = statisticstemp[key];
                            amount = amount + element.length;
                        }
                        /*if (props.imagetaken != amount) {
                            props.setImageTaken(amount);
                        }*/
                        setStatistics(statisticstemp);
                    }
                }, (error) => {
                    console.log(error);
                });
        }
    }

    
    var years = {};
    var yearlist = [];
    let lastyear = -1;
    let lastmonth = -1;

    for (const key in statistics) {
        const element = statistics[key];
        for (let i = 0; i < element.length; i++) {
            const event = element[i];
            var stamp = new Date(event.timestamp);
            var year = stamp.getFullYear();
            var month = stamp.getMonth() + 1;
            var day = stamp.getDate();
            if(year > lastyear){
                lastyear = year;
            }
            if(month > lastmonth){
                lastmonth = month;
            }

            if (years[year] == undefined) {
                years[year] = {};
                yearlist.push(year);
            }
            if (years[year][month] == undefined) {
                years[year][month] = {};
            }
            if (years[year][month][day] == undefined) {
                years[year][month][day] = [];
            }
            years[year][month][day].push(event);
        }
    }

    if(selectedYear == "none" && selectedMonth == "none"){
        if(lastyear != -1 && selectedYear != lastyear && selectedYear === "none"){
            setSelectedYear(lastyear);
        }
        if(lastmonth != -1 && selectedMonth != lastmonth && selectedMonth === "none"){
            setSelectedMonth(lastmonth);
        }    
    }

    function DaySelecter() {
        if (selectedMonth != "none") {
            var amount = new Date(parseInt(selectedYear), parseInt(selectedMonth), 0).getDate();
            var darr = [];
            for (let i = 0; i < amount; i++) {
                darr.push(i + 1);
            }

            return (
                <GridItem xs={4}>
                    Selected day
                    <Select fullWidth value={selectedDay} onChange={(e) => {
                        setSelectedDay(e.target.value);
                    }}>
                        <MenuItem value={"none"} key={0}>Choose</MenuItem>
                        {
                            darr.map(function (m, i) {
                                return <MenuItem value={m} key={m}>{m}</MenuItem>;
                            })
                        }
                    </Select>
                </GridItem>
            )
        }
        return null;
    }

    function MonthSelecter() {
        if (selectedYear != "none") {
            var marr = [];
            for (let i = 0; i < 12; i++) {
                marr.push(i + 1);
            }

            return (
                <GridItem xs={4}>
                Selected month
                    <Select fullWidth value={selectedMonth} onChange={(e) => {
                        setSelectedMonth(e.target.value);
                        setSelectedDay("none");
                    }}>
                        <MenuItem value={"none"} key={0}>Choose</MenuItem>
                        {
                            marr.map(function (m, i) {
                                return <MenuItem value={m} key={m}>{m}</MenuItem>;
                            })
                        }
                    </Select>
                </GridItem>
            )
        }
        return null;
    }

    function YearSelector() {
        return (
            <GridItem xs={4}>
            Selected year
                <Select fullWidth value={selectedYear} onChange={(e) => {
                    setSelectedYear(e.target.value);
                    setSelectedMonth("none");
                    setSelectedDay("none");
                }}>
                    <MenuItem value={"none"} key={0}>Choose</MenuItem>
                    {
                        yearlist.map(function (year, i) {
                            return <MenuItem value={year} key={year}>{year}</MenuItem>;
                        })
                    }
                </Select>
            </GridItem>
        )
    }

    
    return (
        <GridContainer>
            {props.nobar ? null : <>
                <YearSelector></YearSelector>
                <MonthSelecter></MonthSelecter>
                <DaySelecter></DaySelecter>
            </>}
            {props.nobar ? <Typography variant="h6">Statistics current Month</Typography> : null}
            <br></br>
            <GridItem xs={12}>
                <DataPreparer nobar={props.nobar} years={years} selectedDay={selectedDay} selectedYear={selectedYear} selectedMonth={selectedMonth} ></DataPreparer>
            </GridItem>
        </GridContainer>
    )
}
