import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Grid from '@material-ui/core/Grid';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {Input, TextField, Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

var axios = require("axios");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const [data, setData] = React.useState(undefined);
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [centerids, setCenterIds] = React.useState("");
  const [deviceids, setDeviceIds] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

  const classes = useStyles();
  if (data == undefined) {
    axios.post(window.serverip + "/getUserDataByID/" + window.loggedinid, {
      sessionid: sessionStorage.getItem("sessionid")
    })
      .then((response) => {
        setData(response.data[0]);
        setName(response.data[0].name);
        setSurname(response.data[0].surname);
        setEmail(response.data[0].email);
        setCenterIds(response.data[0].centerids);
        setDeviceIds(response.data[0].deviceids);
      }, (error) => {
        console.log(error);
      });
    return null;
  }

  function saveData() {
    axios.post(window.serverip + "/setUserData/" + window.loggedinid, {
      name: name,
      surname: surname,
      email: email,
      sessionid: sessionStorage.getItem("sessionid")
    })
    .then((response) => {
        //console.log(response.data);
        setSnackbarSeverity("success");
        setSnackbarMsg("User updated successfully");
        setOpenSnackbar(true);
    }, (error) => {
        //console.log(error);
        setSnackbarSeverity("error");
        setSnackbarMsg("network error");
        setOpenSnackbar(true);
    });
  }


  function handleSnackbarClose() {
    setOpenSnackbar(false);
    setSnackbarMsg("");
}

  return (
    <div>
      <Grid container>
            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} >
                    {snackbarMsg}
                </Alert>
            </Snackbar>
        <Grid item xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Profilesettings</h4>
              <p className={classes.cardCategoryWhite}>Here you can edit you personal Data.</p>
            </CardHeader>
            <CardBody>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField label="Name" value={name} variant="outlined" onChange={(e) => { setName(e.target.value); }}></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Surname" value={surname} variant="outlined" onChange={(e) => { setSurname(e.target.value); }}></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Email" value={email} variant="outlined" onChange={(e) => { setEmail(e.target.value); }}></TextField>
                </Grid>
                {/*
                <Grid item xs={3}>
                  <TextField label="Zugeteilte Center:" value={centerids} variant="outlined" disabled></TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField label="Zugeteilte Geräte:" value={deviceids} variant="outlined" disabled></TextField>
                </Grid>
                */
                }
              </Grid>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={saveData}>Save</Button>
            </CardFooter>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
