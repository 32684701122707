import React from 'react';
import Grid from '@material-ui/core/Grid';
import SceneMedia from './SceneMedia';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
var axios = require("axios");

export default function Scene(props) {
    const [deleted, setDeleted] = React.useState(false);

    var sceneid = props.sceneid;
    var sceneids = props.sceneids;
    var setSceneids = props.setSceneids;
    let checked = sceneids.includes(sceneid);

    if(deleted){
        return null;    
    }

    function deleteCustomScene() {
        const centerid = window.centerids[0];
        axios.post(window.serverip + "/deleteCustomScene/" + centerid + "/" + sceneid, {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                if(response.data.error != undefined && response.data.error === "in_use"){
                    alert("You can't delete scenes which are in use.")
                } else {
                    setDeleted(true);
                }
            }, (error) => {
                console.log(error);
            });
    }

    if (props.customScene) {
        let backgroundimg = props.customScene.background;
        return (<Grid item xs={2}>
            <div style={{ width: "100%", borderStyle: "solid" }}>
                <input onChange={checkScene} type="checkbox" checked={checked}></input>
                <IconButton onClick={deleteCustomScene}>
                    <Delete></Delete>
                </IconButton>
                <p>Scene:{sceneid}</p>
                <img style={{ width: "100%" }} src={backgroundimg}></img>
            </div>
        </Grid>)
    }


    return (<Grid item xs={2}><div style={{ width: "100%", borderStyle: "solid" }}><input onChange={checkScene} type="checkbox" checked={checked}></input><p>Scene:{sceneid}</p><SceneMedia sceneid={sceneid} type={props.type}></SceneMedia></div></Grid>)

    function checkScene(e) {
        //console.log(boxid);
        //console.log(sceneid);

        if (sceneids.includes(sceneid)) {
            //console.log("uncheck");
            var arraycopy = sceneids.filter(function (value, index, arr) { return value != sceneid; });
            //arraycopy.sort((a, b) => a - b)
            setSceneids(arraycopy);
        } else {
            //console.log("check");
            if (sceneids.length != 20) {
                var arraycopy = [...sceneids];
                arraycopy.push(sceneid);
                arraycopy.sort((a, b) => a - b)
                setSceneids(arraycopy);
            }
        }
    }
}
