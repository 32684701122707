import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Button, CircularProgress, Paper, Snackbar } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import MuiAlert from '@material-ui/lab/Alert';
import Scene from './Scene';

var axios = require("axios");

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function SelfieBoxList(props) {
    const [customScenes, setCustomScenes] = React.useState(undefined);

    if (customScenes == undefined) {
        axios.post(window.serverip + "/getCustomScenes/" + window.centerids[0], {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                setCustomScenes(response.data);
            }, (error) => {
                console.log(error);
            });
        return <><CircularProgress></CircularProgress><br></br>Loading Scenes</>;
    }

    var devicearray = window.deviceids;

    return (<>{devicearray.map((device) => <SelfieBoxListEntry id={device} key={device} customScenes={customScenes} onlyentry={devicearray.length === 1}></SelfieBoxListEntry>)}</>)
}

function SelfieBoxListEntry(props) {
    const [data, setData] = React.useState(undefined);
    const [expanded, setExpanded] = React.useState(props.onlyentry);
    const classes = useStyles();
    var boxid = props.id;
    if (data === undefined) {
        axios.post(window.serverip + "/getDevicebyID/" + boxid, {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                setData(response.data[0]);
            }, (error) => {
                console.log(error);
            });
        return null;
    }

    var name = "Loading Boxdata...";
    if (data != undefined) {
        name = "Box " + data.location;
    }

    return (
        <Accordion expanded={expanded} onChange={(e, bool) => {
            setExpanded(bool);
        }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onChange={(e) => {
                    console.log(e.target.expanded);
                }}
            >
                <Typography className={classes.heading}>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {expanded ? <Details boxid={boxid} customScenes={props.customScenes}></Details> : null}
            </AccordionDetails>
        </Accordion>
    )
}

function Details(props) {
    const [data, setData] = React.useState(undefined);
    var boxid = props.boxid;
    if (data == undefined) {
        axios.post(window.serverip + "/getScenesbyDeviceID/" + boxid, {
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                setData(response.data[0])
            }, (error) => {
                console.log(error);
            });
        return null;
    }
    var sceneids = [];
    for (const [key, value] of Object.entries(data)) {
        if (key.startsWith("scene")) {
            sceneids.push(value);
        }
    }

    return (<ScenesWithSelectedScenes sceneids={sceneids} boxid={boxid} customScenes={props.customScenes}></ScenesWithSelectedScenes>)
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ScenesWithSelectedScenes(props) {
    const [sceneids, setSceneids] = React.useState(props.sceneids);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMsg, setSnackbarMsg] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
    const customScenes = props.customScenes;

    var boxid = props.boxid;
    var availablescenes = [];

    for (let i = 1; i <= 101; i++) {
        if (i != 92) {
            availablescenes.push({ sceneid: i, type: "photo" });
            if (i == 29 || i == 42 || i == 43 || i == 44 || i == 94) {
                availablescenes[availablescenes.length - 1].type = "video";
            }
        }
    }


    function handleSnackbarClose() {
        setOpenSnackbar(false);
        setSnackbarMsg("");
    }

    function handleSceneChange() {
        if (sceneids.length != 20) {
            alert("Choose exactly 20 scenes.");
            return;
        }
        axios.post(window.serverip + "/setScenes/" + boxid, {
            scenes: sceneids,
            sessionid: sessionStorage.getItem("sessionid")
        })
            .then((response) => {
                //console.log(response.data[0]);
                if (window.rank === 0) {
                    setSnackbarSeverity("warning");
                    setSnackbarMsg("We will deal with your application as soon as possible");
                    setOpenSnackbar(true);
                } else if (window.rank >= 1) {
                    setSnackbarSeverity("success");
                    setSnackbarMsg("Scenes changed successfully");
                    setOpenSnackbar(true);
                }
            }, (error) => {
                //console.log(error);
                setSnackbarSeverity("error");
                setSnackbarMsg("Network error");
                setOpenSnackbar(true);
            });
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            You have selected {sceneids.length} / 20 scenes.
        </Grid>
        {availablescenes.map((scene) => <Scene key={scene.sceneid} sceneid={scene.sceneid} type={scene.type} sceneids={sceneids} setSceneids={setSceneids}></Scene>)}
        {customScenes.map((scene) => <Scene key={scene.custom_sceneid} sceneid={scene.custom_sceneid} sceneids={sceneids} setSceneids={setSceneids} customScene={scene}></Scene>)}
        <Grid item xs={12}>
            <Button onClick={handleSceneChange}>Change</Button>
        </Grid>
        <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} >
                {snackbarMsg}
            </Alert>
        </Snackbar>
    </Grid>

}

